const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGIN_LOGOUT',
  
  login: credentials => ({
    type: actions.LOGIN_REQUEST,
    payload: { credentials },
  }),

  loginSuccess: data => ({
    type: actions.LOGIN_SUCCESS,
    payload: data,
  }),

  loginError: error => ({
    type: actions.LOGIN_ERROR,
    payload: { error },
  }),

  resetPassword: credentials => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload: { credentials },
  }),

  resetPasswordSuccess: data => ({
    type: actions.RESET_PASSWORD_SUCCESS,
    payload: data,
  }),

  resetPasswordError: error => ({
    type: actions.RESET_PASSWORD_ERROR,
    payload: { error },
  }),

  logout: () => ({
    type: actions.LOGOUT,
  }),

  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS,
  })
};

export default actions;

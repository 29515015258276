import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { simpleQueryFirestoreWithFilter, existInFirestoreDocument } from "../../components/utility/firestore.helper";
import { rsf } from '@iso/lib/firebase/firebase';
import { call } from 'redux-saga/effects';
import omit from 'lodash/omit';

const COLLECTION_NAME = 'users';
const COLLECTION_NAME_PROPERTY = 'properties';

function* loadUsers(query) {
	try {
		const filters = query && query.payload.filters;
		const data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME, filters);
		yield put(actions.loadUsersSuccess(data));
	} catch (error) {
		yield put(actions.loadUsersError(error));
	}
}

function* loadClients(query) {
	try {
		const filters = query && query.payload.filters;
		const data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME, filters);
		yield put(actions.loadClientsSuccess(data));
	} catch (error) {
		yield put(actions.loadClientsError(error));
	}
}

function* loadAutocompleteUsers(query) {
	try {
		const filters = query && query.payload.data;
		const data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME, filters);
    	yield put(actions.loadUsersAutocompleteSuccess(data));
	} catch (error) {
		yield put(actions.loadUsersAutocompleteError(error));
	}
}

function* saveClient({ payload }) {
	const { data, actionName } = payload;
	try {
		switch (actionName) {
			case 'update':
				yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
					...omit(data, ['key']),
				});
				break;
			default:
				yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
				break;
		}
		yield put({ type: actions.LOAD_CLIENTS, payload: { filters: payload.filters }});
		yield put({ type: actions.LOAD_USERS_AUTOCOMPLETE, payload: { filters: payload.filters }});
		yield put(actions.cleanNotificationMessage());
	} catch (error) {
		yield put(actions.saveUserError(error));
	}
}

function* saveUser({ payload }) {
	const { data, actionName } = payload;
	try {
	  switch (actionName) {
		case 'update':
			yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
			...omit(data, ['key']),
		  	});
			yield put(actions.updateUserSuccess());
		  break;
		default:
			yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
			yield put(actions.saveUserSuccess());
		  break;
		}
	  yield put({ type: actions.LOAD_USERS, payload: { filters: payload.filters } });
	  yield put(actions.cleanNotificationMessage());
	} catch (error) {
	  yield put(actions.saveUserError(error));
	}
}

function* deleteUser({ payload }) {
	const { data } = payload;
	try {
		const exist = yield existInFirestoreDocument(COLLECTION_NAME_PROPERTY, {'value': data.id , 'operator': '==', 'field': 'ownerId' });;
		if(!exist.length) {
		  yield call(rsf.firestore.deleteDocument, 'users/'+ data.key)
		  yield put({ type: data.role === 'client' ? actions.LOAD_CLIENTS : actions.LOAD_USERS, payload: { filters: payload.filters }});
		}
		else {
		  yield put(actions.deleteUserError());
		}
		yield put(actions.cleanNotificationMessage());
	} catch (error) {
	  yield put(actions.deleteUserError(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_USERS, loadUsers),
		takeEvery(actions.LOAD_CLIENTS, loadClients),
		takeEvery(actions.LOAD_USERS_AUTOCOMPLETE, loadAutocompleteUsers),
		takeEvery(actions.SAVE_USER, saveUser),
		takeEvery(actions.SAVE_CLIENT, saveClient),
		takeEvery(actions.DELETE_USER, deleteUser)
	]);
}

import actions from './actions';

const initState = { 
  idToken: localStorage.getItem('id_token'),
  role: localStorage.getItem('id_role'),
  agencyIsActive: localStorage.getItem('agency_is_active') === 'false' ?  false : true,
  isLoadingLogin: false,
  isLoadingResetPassword: false,
  error: null,
  resetPasswordMessage: null
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOGIN_REQUEST:
      return {
        isLoadingLogin: true
      };
    case actions.LOGIN_SUCCESS:
      return {
        isLoadingLogin: false,
        idToken: payload.firebaseToken,
        role: payload.role,
				agencyIsActive: payload.agencyIsActive
      };
    case actions.LOGIN_ERROR:
      return {
        ...initState,
        isLoadingLogin: false,
        idToken: null,
        error: payload.error.message
      };
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...initState,
        isLoadingResetPassword: true
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...initState,
        isLoadingResetPassword: false,
        resetPasswordMessage: payload.message
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...initState,
        isLoadingResetPassword: false,
        resetPasswordMessage: payload.error.message
      };
    case actions.LOGOUT:
      return initState;
    case actions.LOGOUT_SUCCESS:
      return {
        ...initState,
        idToken: null,
        error: null,
        role: null
      };  
    default:
      return state;
  }
}

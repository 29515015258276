import moment from 'moment';

export default class DateFormatter {
  static getCurrentDate = () => {
    return moment();
  }

  static getDate = (date) => {
    return moment(date);
  }

  static getValueOfDate = (date) => {
    return date ? moment(date).valueOf() : moment().valueOf();
  }

  static getValueOfStartedDate = (date) => {
    return date ? moment(date).startOf("day").valueOf() : moment().startOf("day").valueOf();
  }

  static getFormatString = (date, format) => {
    return date ? moment(date).format(format) : moment().format(format);
  }

  static addDaysToCurrentDate = (date, days, key) => {
    return date ? moment(date).add(days, key) : moment().add(days, key);
  }

  static subtractDaysToCurrentDate = (date, days, key) => {
    return moment(date).subtract(days, key);
  }

  static diffDays = (startDate, endDate) => {
    return moment(endDate).diff(startDate, 'days')
  }

  static getCleanUTCValue = (date) => {
    return date ? date.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).valueOf() : date;
  }

}

import actions from "./actions";

const initState = {
  isLoadingProperties: false,
  isLoadingProperty: false,
  isLoadingImageProperties: false,
  errorMessage: null,
  resetMessage: false,
  successMessage: null,
  properties: [],
  currentGallery: [],
  propertiesSelector: {},
  property: {
    id: null,
    price: 0,
    name: null,
    guests: null,
    single_bed: 0,
    double_bed: 0,
    bedrooms: 0,
    bathrooms: 0,
    owner: null,
    ownerId: null,
    comments: null,
    description: {
      en: null,
      es: null,
      fr: null,
    },
    active: null,
    enabled: null,
    lat: null,
    lng: null,
    address: null,
    city: null,
    country: null,
    zipCode: null,
    feature: [],
    gallery: [],
    prices: [],
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_PROPERTY:
      return {
        ...state,
        currentGallery: [],
        isLoadingProperty: true,
      };
    case actions.LOAD_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoadingProperty: false,
        property: payload.data,
      };
    case actions.LOAD_PROPERTY_ERROR:
      return {
        ...state,
        isLoadingProperty: false,
      };
    case actions.LOAD_PROPERTIES:
      return {
        ...state,
        isLoadingProperties: true,
      };
    case actions.LOAD_PROPERTIES_SUCCESS:
      return {
        ...state,
        isLoadingProperties: false,
        properties: payload.data,
      };
    case actions.LOAD_PROPERTIES_ERROR:
      return {
        ...state,
        isLoadingProperties: false,
      };
    case actions.LOAD_PROPERTY_SELECTOR_SUCCESS:
      return {
        ...state,
        isLoadingProperties: false,
        propertiesSelector: payload.data,
      };
    case actions.UPDATE_STATUS_PROPERTY:
      return {
        ...state,
        isLoadingProperties: true,
      };
    case actions.UPDATE_STATUS_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoadingProperties: false,
        errorMessage: null,
        successMessage: "Status has been edited successfully",
        resetMessage: !state.resetMessage,
      };
    case actions.UPDATE_STATUS_PROPERTY_ERROR:
      return {
        ...state,
        isLoadingProperties: false,
        successMessage: null,
        errorMessage: "There is a loading problem",
        resetMessage: !state.resetMessage,
      };
    case actions.SAVE_PROPERTY:
      return {
        ...state,
        isLoadingProperties: true,
        property: payload.data,
      };
    case actions.SAVE_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoadingProperties: false,
        successMessage: "Property has been saved successfully",
        errorMessage: null,
        resetMessage: !state.resetMessage,
      };
    case actions.UPDATE_PROPERTY:
      return {
        ...state,
        isLoadingProperties: true,
        property: payload.data,
      };
    case actions.UPDATE_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoadingProperties: false,
        successMessage: "Property has been edited successfully",
        errorMessage: null,
        resetMessage: !state.resetMessage,
      };
    case actions.STORE_PROPERTY_ERROR:
      return {
        ...state,
        isLoadingProperties: false,
        successMessage: null,
        errorMessage: "There is a loading problem",
      };
    case actions.GET_URL_IMAGE_PROPERTY:
      return {
        ...state,
        isLoadingImageProperties: true,
      };
    case actions.GET_URL_IMAGE_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoadingImageProperties: false,
        currentGallery: payload.data,
        errorMessage: null,
        successMessage: null,
      };
    case actions.GET_URL_IMAGE_PROPERTY_ERROR:
      return {
        ...state,
        isLoadingImageProperties: false,
        successMessage: null,
        errorMessage: "There is a loading problem",
      };
    case actions.DELETE_PROPERTY_SUCCESS:
      return {
        ...state,
        successMessage: "Property has been deleted successfully",
        resetMessage: !state.resetMessage,
        errorMessage: null,
      };
    case actions.DELETE_PROPERTY_ERROR:
      return {
        ...state,
        successMessage: null,
        resetMessage: !state.resetMessage,
        errorMessage:
          "It is not possible to delete this property, you have an active booking associated with it",
      };
    case actions.DUPLICATE_PROPERTY_SUCCESS:
      return {
        ...state,
        successMessage: "Property has been duplicated successfully",
        resetMessage: !state.resetMessage,
        errorMessage: null,
      };
    case actions.DUPLICATE_PROPERTY_ERROR:
      return {
        ...state,
        successMessage: null,
        resetMessage: !state.resetMessage,
        errorMessage:
          "It is not possible to duplicated this property",
      };
    case actions.REMOVE_NOTIFICATION_MESSAGE:
      return {
        ...state,
        successMessage: null,
        resetMessage: null,
        errorMessage: null,
      };
    default:
      return state;
  }
}

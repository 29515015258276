const actions = {
  GET_INVOICES: 'GET_INVOICES',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  UPDATE_LIST_VIEW: 'UPDATE_LIST_VIEW',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICES_BULK: 'UPDATE_INVOICES_BULK',
  SELECT_CURRENT_INVOICE: 'SELECT_CURRENT_INVOICE',
  EDIT_INVOICE: 'INVOICE_EDIT_INVOICE',
  UPDATE_INVOICE_ITEM_BOOKING: 'UPDATE_INVOICE_ITEM_BOOKING',
  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICE_SUCCESS:'GET_INVOICE_SUCCESS',
  GET_INVOICE_ERROR: 'GET_INVOICE_ERROR',
  FIX_PAYMENTS_INVOICE: 'FIX_PAYMENTS_INVOICE',

  initData: filter => ({ type: actions.GET_INVOICES, payload:{
    filter
  } }),
  initDataItems: () => ({  type: 'ITEM_LOAD_FROM_FIRESTORE' }),
  fixPaymentsInvoice: () => ({ type: 'FIX_PAYMENTS_INVOICE'}),
  //action delete from invoices list
  deleteInvoice: (selected, filter) => {
    return (dispatch, getState) => {
      const invoices = getState().Invoice.invoices;
      const newInvoices = [];
      invoices.forEach(invoice => {
        const selectedIndex = selected.indexOf(invoice.key);
        if (selectedIndex === -1) {
          newInvoices.push(invoice);
        }else{
          dispatch({
            type: actions.UPDATE_INVOICE,
            payload: {
              invoices: newInvoices,
              invoice: invoice,
              filter,
              actionName: 'delete'
            }
          });
        }
      });

    };
  },
  //update invoice in store
  editInvoice: invoice => ({ type: actions.EDIT_INVOICE, invoice }),
  //save in database
  updateInvoice: (invoice, filter, actionName = 'insert') => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATE_INVOICE,
        payload:{
          invoice,
          filter,
          actionName: actionName
        }
      });
    };
  },
  updateListView: (listView) => ({ type: actions.UPDATE_LIST_VIEW, payload: {listView: listView } }),
  updateInvoicesBulk: (invoices, series, filter) => {
    return {
      type: actions.UPDATE_INVOICES_BULK,
      payload: {
        invoices,
        series,
        filter
      }
    }
  },
  invoiceUpdateItems: dataBooking =>
  { 
    return (dispatch, getState) => {
      let itemList = [];
      dataBooking.cleaning_services_item_list.map(item => {
        itemList.push(item);
        return true;
      });
      dataBooking.arrival_item_list.map(item => {
        itemList.push(item);
        return true;
      });
      dataBooking.departure_item_list.map(item => {
        itemList.push(item);
        return true;
      });
      dispatch({
        type: actions.UPDATE_INVOICE_ITEM_BOOKING,
        payload:{
          items: itemList,
          booking: dataBooking,
        }
      });
    }
  },
  getInvoice: (id) => {
    return { 
      type: actions.GET_INVOICE,
      payload: { id } 
    };
  },
  getInvoiceSuccess: data => ({
    type: actions.GET_INVOICE_SUCCESS,
    payload: { data },
  }),
  getInvoiceError: error => ({
    type: actions.GET_INVOICE_ERROR,
    payload: { error },
  }),
};
export default actions;

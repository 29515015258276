import moment from "moment";
const localDataName = "mateInvoice";
var today = new Date();
const newInvoice = {
  orderStatus: "draft",
  orderDate: moment(new Date(today.getFullYear(), today.getMonth() + 1, 0))
    .toDate()
    .getTime(),
  orderMonth: null,
  currency: "€",
  billTo: "",
  billToAddress: "",
  billFrom: "A-Digital",
  billFromAddress: "Carrer Punta Natí 8 Poima, Oficina A 07714 Mahón - Islas Baleares",
  billFromPhone: "+34 111 22 22 22",
  billFromEmail: "alain@a-digital.net",
  billFromIban: "ES1111111111111111111111",
  nifFrom: "Z23757575",
  companyFrom: "A-Digital",
  invoiceList: [
    {
      key: 1,
      itemName: "",
      itemType: "A",
      noTaxable: false,
      costs: 0,
      qty: 1,
      price: 0,
      createdAt: Date.now(),
    },
  ],
  taxable: 0,
  subTotal: 0,
  vatRate: 21,
  vatPrice: 0,
  totalCost: 0,
  emailSent: false,
  type: 'invoice', /* type: invoice or note */
  deleted_at: null,
};

const labelsInvoice = {
  invoice: {
    singular: "Invoice",
    plural: "Invoices"
  },
  note: {
    singular: "Note",
    plural: "Notes"
  }
}

const initialListView = {
  top: 0,
  currentInvoice: null,
  loading: false,
  selected: [],
  isRangePicker: true,
  singleResult: null,
  startDateId: "start_date_id",
  endDateId: "end_date_id",
  filter: {
    startDate: null,
    endDate: null,
    billToId: null,
    billToName: "",
    orderStatus: null,
    invoice_serie: null,
    invoiceSerieName: "",
    type: null
  }
}

export { localDataName, newInvoice, labelsInvoice, initialListView };

import actions from './actions';

const initState = {
  isLoadingBookings: false,
  isLoadingArrivalDeparture: false,
  isLoadingBooking: false,
  isLoadingGetReservationNumber: false,
  nextReservationNumber: '',
  errorMessage: false,
  resetMessage: false,
  successMessage: null,
  bookings: [],
  arrivalsDepartures: [],
  bookingsWithProperties: [],
  booking: {
    id: '',
    start_date: null,
    end_date: null,
    departure: '00:00',
    arrive: '00:00',
    message: "",
    guest: null,
    property_id: null,
    price: "0",
    status: "0",
    guests: null,
    reservation_number: null,
    infants_under_three: 0,
    name: null,
    surname: null,
    email: null,
    phone: null,
    ocupation: "owner",
    departure_date: null,
    departure_time: "00:00",
    arrival_date: null,
    arrival_time: "00:00",
    departure_flight_number: null,
    arrival_flight_number: null,
    changeover_clean: 0,
    make_up_all_beds: 0,
    arrival_item_list: [],
    agent_id: null,
    owner_id: null,
    arrival_special_requirements: null,
    cleaning_services_item_list: [],
    collect_dirty_linens_and_launder: 0,
    departure_special_requirements: null,
  }
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_BOOKING:
      return {
        ...state,
        isLoadingBooking: true,
        errorMessage: '',
      };
    case actions.LOAD_BOOKING_SUCCESS:
      return {
        ...state,
        isLoadingBooking: false,
        booking: payload.data,
      };
    case actions.LOAD_BOOKING_ERROR:
      return {
        ...state,
        isLoadingBooking: false,
      };
    case actions.GET_NEXT_RESERVATION_NUMBER:
      return {
        ...state,
        isLoadingGetReservationNumber: true,
      };
    case actions.GET_NEXT_RESERVATION_NUMBER_SUCCESS:
      return {
        ...state,
        isLoadingGetReservationNumber: false,
        nextReservationNumber: payload.data,
      };
    case actions.GET_NEXT_RESERVATION_NUMBER_ERROR:
      return {
        ...state,
        isLoadingGetReservationNumber: false,
      };
    case actions.LOAD_BOOKINGS:
      return {
        ...state,
        isLoadingBookings: true,
      };
    case actions.LOAD_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoadingBookings: false,
        bookings: payload.data,
      };
    case actions.LOAD_BOOKINGS_ERROR:
      return {
        ...state,
        isLoadingBookings: false,
      };
    case actions.LOAD_BOOKINGS_WITH_PROPERTIES:
      return {
        ...state,
        isLoadingBookings: true,
      };
    case actions.LOAD_BOOKINGS_WITH_PROPERTIES_SUCCESS:
      return {
        ...state,
        isLoadingBookings: false,
        bookingsWithProperties: payload.data,

      };
    case actions.LOAD_BOOKINGS_WITH_PROPERTIES_ERROR:
      return {
        ...state,
        isLoadingBookings: false,
      };
    case actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES:
      return {
        ...state,
        isLoadingArrivalDeparture: true,
      };
    case actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_SUCCESS:
      return {
        ...state,
        isLoadingArrivalDeparture: false,
        arrivalsDepartures: payload.data,
      };
    case actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_ERROR:
      return {
        ...state,
        isLoadingArrivalDeparture: false,
      };
    case actions.SAVE_BOOKING:
      return {
        ...state,
        isLoadingBookings: true,
        booking: payload.data,
      };
    case actions.SAVE_BOOKING_SUCCESS:
      return {
        ...state,
        isLoadingBookings: false,
        errorMessage: null,
        successMessage: 'Booking has been saved successfully',
        resetMessage: !state.resetMessage,
      };
    case actions.UPDATE_BOOKING:
      return {
        ...state,
        isLoadingBookings: true,
        booking: payload.data,
      };
    case actions.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        isLoadingBookings: false,
        errorMessage: null,
        successMessage: 'Booking has been edited successfully',
        resetMessage: !state.resetMessage,
      };
    case actions.STORE_BOOKING_ERROR:
      return {
        ...state,
        isLoadingBookings: false,
        successMessage: null,
        resetMessage: !state.resetMessage,
        errorMessage: 'There is a loading problem',
      };
    case actions.UPDATE_STATUS_BOOKING:
      return {
        ...state,
        isLoadingBookings: true,
      };
    case actions.UPDATE_STATUS_BOOKING_SUCCESS:
      return {
        ...state,
        isLoadingBookings: false,
        errorMessage: null,
        successMessage: 'Status has been edited successfully',
        resetMessage: !state.resetMessage,
      };
    case actions.UPDATE_STATUS_BOOKING_ERROR:
      return {
        ...state,
        isLoadingBookings: false,
        errorMessage: 'There is a loading problem',
        successMessage: 'Status has been edited successfully',
        resetMessage: !state.resetMessage,
      };
    case actions.DELETE_BOOKING_SUCCESS:
        return {
          ...state,
          successMessage: 'Booking has been deleted successfully',
          resetMessage: !state.resetMessage,
          errorMessage: null,
        }; 
    case actions.DELETE_DELETE_ERROR:
      return {
        ...state,
        successMessage: null,
        resetMessage: !state.resetMessage,
        errorMessage: 'It is not possible to delete this booking',
      };
    case actions.REMOVE_NOTIFICATION_MESSAGE:
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
        resetMessage: null,
      };
    default:
      return state;
  }
};

import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import { simpleQueryFirestoreWithFilter, existInFirestoreDocument } from '../../components/utility/firestore.helper';
import { rsf } from '@iso/lib/firebase/firebase';
import { call } from 'redux-saga/effects';
import omit from 'lodash/omit';

const COLLECTION_NAME_PROPERTIES = 'properties';
const COLLECTION_NAME_BOOKINGS = 'bookings';

function* loadProperties(query) {
  try {
    const filtersProperty = query && query.payload.filterProperty;
    const data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME_PROPERTIES, filtersProperty);
    yield put(actions.loadPropertiesSuccess(data));
  } catch (error) {
    yield put(actions.loadPropertiesError(error));
  }
}

function* loadPropertiesSelector(query) {
  try {
    const filtersProperty = query && query.payload.filterProperty;
    const data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME_PROPERTIES, filtersProperty);
    yield put(actions.loadPropertiesSelectorSuccess(data));
  } catch (error) {
    yield put(actions.loadPropertiesError(error));
  }
}

function* loadProperty(query) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, COLLECTION_NAME_PROPERTIES + '/' + query.payload.id);
    const data = snapshot.data();
    yield put(actions.loadPropertySuccess(data));
  } catch (error) {
    yield put(actions.loadPropertyError(error));
  }
}

function* updateStatusProperty({ payload }) {
  const { data } = payload;
  try {
    yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME_PROPERTIES}/${data.key}`, { active: data.status });
    yield put(actions.updateStatusPropertySuccess());
    yield put(actions.cleanNotificationMessage());
  } catch (error) {
    yield put(actions.updateStatusPropertyError(error));
  }
}

function* addRefGalleryToProperty({ payload }) {
  const { data, id } = payload;
  try {
    yield call(rsf.firestore.updateDocument, `${COLLECTION_NAME_PROPERTIES}/${id}`, { gallery: data });
    yield put(actions.getCurrentGallery(data));
    //yield put(actions.updateStatusPropertySuccess());
  } catch (error) {
    //yield put(actions.updateStatusPropertyError(error));
  }
}

function* deleteProperty({ payload }) {
	const { data } = payload;
	try {
    const exist = yield existInFirestoreDocument(COLLECTION_NAME_BOOKINGS, {'value': data.property_id , 'operator': '==', 'field': 'property_id' });;
    if(!exist.length) {
      yield call(rsf.firestore.deleteDocument, 'properties/'+ data.key)
      yield put(actions.deletePropertySuccess());
      yield put(actions.cleanNotificationMessage());
      yield put({ type: actions.LOAD_PROPERTIES, payload: {filterProperty: null}});
    }
    else {
      yield put(actions.deletePropertyError());
      yield put(actions.cleanNotificationMessage());
    }
    
	} catch (error) {
	    yield put(actions.deletePropertyError(error));
      yield put(actions.cleanNotificationMessage());
	}
}

function* duplicateProperty({ payload }) {
  const { data } = payload;
  try {
    const duplicatedProperty = Object.assign({}, data) ;
    duplicatedProperty.name = `${duplicatedProperty.name}-2`
    duplicatedProperty.id = `${duplicatedProperty.id}-2`
    const removeUnUseProperties = {...omit(duplicatedProperty, ['activated', 'key', 'property_id'])}
    yield call(rsf.firestore.addDocument, COLLECTION_NAME_PROPERTIES, removeUnUseProperties);
    yield put(actions.duplicatePropertySuccess());
    yield put({ type: actions.LOAD_PROPERTIES, payload: {filterProperty: null}});
  } catch (error) {
    yield put(actions.duplicatePropertyError(error));
    yield put(actions.cleanNotificationMessage());
  }
}

function* saveProperty({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'update':
        yield call(rsf.firestore.setDocument, `${COLLECTION_NAME_PROPERTIES}/${data.key}`, {
          ...omit(data, ['key']),
        });
        yield put(actions.updatePropertySuccess());
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME_PROPERTIES, {
          ...omit(data, ['user', 'key']),
        });
        yield put(actions.savePropertySuccess());
        break;
    }
    yield put(actions.cleanNotificationMessage());
  } catch (error) {
      console.log(error);
      yield put(actions.savePropertyError(error));
      yield put(actions.cleanNotificationMessage());
    }
  }

  function* saveGallery({ payload }) {
    const { data } = payload;
    const folderName = 'properties'
    try {
      const file = data.originFileObj;
      yield call(rsf.storage.uploadFile, folderName + '/' + data.name, file);
      yield put(actions.saveGallerySuccess());
    } catch (error) {
      yield put(actions.saveGalleryError(error));
    }
}

function* getCurrentGallery({ payload }) {
  const { data } = payload;
  const folderName = 'properties'
  try {
    const gallery = [];
    for (let i = 0; i < data.length ; i++){
      const url = yield call(rsf.storage.getDownloadURL, folderName + '/' + data[i].name);
      gallery.push({...data[i], url: url});
    }

    yield put(actions.loadImageUrlSuccess(gallery));

  } catch (error) {
    yield put(actions.loadImageyUrlyError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PROPERTY, loadProperty),
    takeEvery(actions.LOAD_PROPERTIES, loadProperties),
    takeEvery(actions.LOAD_PROPERTIES_SELECTOR, loadPropertiesSelector),
    takeEvery(actions.UPDATE_STATUS_PROPERTY, updateStatusProperty),
    takeEvery(actions.SAVE_PROPERTY, saveProperty),
    takeEvery(actions.UPDATE_PROPERTY, saveProperty),
    takeEvery(actions.SAVE_GALLERY_PROPERTY, saveGallery),
    takeEvery(actions.GET_URL_IMAGE_PROPERTY, getCurrentGallery),
    takeEvery(actions.ADD_REF_GALLERY_TO_PROPERTY, addRefGalleryToProperty),
    takeEvery(actions.DELETE_PROPERTY, deleteProperty),
    takeEvery(actions.DUPLICATE_PROPERTY, duplicateProperty)
  ]);
}



const DOCUMENT = "PAYMENT_";

const actions = {
  LOAD_PAYMENTS: DOCUMENT + "LOAD_PAYMENTS",
  LOAD_PAYMENTS_SUCCESS: DOCUMENT + "LOAD_PAYMENTS_SUCCESS",
  LOAD_PAYMENTS_ERROR: DOCUMENT + "LOAD_PAYMENTS_ERROR",

  LOAD_PAYMENTS_DOCUMENT: DOCUMENT + "LOAD_PAYMENTS_DOCUMENT",
  LOAD_PAYMENTS_SUCCESS_DOCUMENT: DOCUMENT + "LOAD_PAYMENTS_SUCCESS_DOCUMENT",
  LOAD_PAYMENTS_ERROR_DOCUMENT: DOCUMENT + "LOAD_PAYMENTS_ERROR_DOCUMENT",

  SAVE_PAYMENT: DOCUMENT + "SAVE_PAYMENT",
  SAVE_PAYMENT_ERROR: DOCUMENT + "SAVE_PAYMENT_ERROR",

  RESET_PAYMENTS: DOCUMENT + "RESET_PAYMENTS",
  RESET_PAYMENTS_ERROR: DOCUMENT + "RESET_PAYMENTS_ERROR",

  TOGGLE_PAYMENTS_HANDLE_MODAL: DOCUMENT + "TOGGLE_PAYMENTS_HANDLE_MODAL",
  PAYMENT_UPDATE: DOCUMENT + "PAYMENTS_UPDATE",

  loadPayments: (filter, singlePayment, meta) => {
    return { type: actions.LOAD_PAYMENTS, payload: { filter, singlePayment, meta } };
  },

  loadPaymentsSuccess: (data) => {
    return {
      type: actions.LOAD_PAYMENTS_SUCCESS,
      payload: { data },
    };
  },

  loadPaymentsError: (error) => ({
    type: actions.LOAD_PAYMENTS_ERROR,
    payload: { error },
  }),

  loadPaymentsDocument: (data = null) => {
    return { type: actions.LOAD_PAYMENTS_DOCUMENT, payload: data };
  },

  loadPaymentsSuccessDocument: (data) => ({
    type: actions.LOAD_PAYMENTS_SUCCESS_DOCUMENT,
    payload: { data },
  }),

  loadPaymentsErrorDocument: (error) => ({
    type: actions.LOAD_PAYMENTS_ERROR_DOCUMENT,
    payload: { error },
  }),

  savePayment: (data, actionName = "insert", filter = null) => ({
    type: actions.SAVE_PAYMENT,
    payload: { data, actionName, filter },
  }),

  savePaymentError: (error) => ({
    type: actions.SAVE_PAYMENT_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_PAYMENTS_HANDLE_MODAL,
    payload: { data },
  }),

  updatePayment: (data) => ({
    type: actions.PAYMENT_UPDATE,
    payload: { data },
  }),
};
export default actions;

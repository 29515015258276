import clone from "clone";
import { newInvoice, initialListView } from "../../pages/Invoice/config";
import actions from "./actions";

const initState = {
  invoices: [],
  editableInvoice: {},
  isLoadingInvoice: true,
  isSubmitted: false,
  listView: {...initialListView}
};

export default function cardReducer(
  state = initState,
  { type, payload, ...action }
) {
  switch (type) {
    case actions.GET_INVOICES: {
      return {
        ...state,
        listView: { ...state.listView, filter: payload.filter },
        editableInvoice: clone(newInvoice),
      };
    }
    case actions.GET_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: action.invoices,
        isLoadingInvoice: false,
        isSubmitted: false
      };
    }
    case actions.UPDATE_INVOICE: {
      return {
        ...state,
        editableInvoice: clone(payload.invoice),
      };
    }
    case actions.UPDATE_LIST_VIEW: {
      return {
        ...state,
        listView: clone(payload.listView)
      }
    }
    case actions.UPDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        isSubmitted: true
      }
    }
    case actions.EDIT_INVOICE:
      return {
        ...state,
        editableInvoice: clone(action.invoice),
      };
    case actions.GET_INVOICE:
      return {
        ...state,
      };
    case actions.GET_INVOICE_SUCCESS:
      return {
        ...state,
        isLoadingInvoice: false,
        editableInvoice: payload.data,
      };
    case actions.GET_iNVOICE_ERROR:
      return {
        ...state,
        editableInvoice: clone(newInvoice),
        isLoadingInvoice: false,
      };
    default:
      return state;
  }
}
